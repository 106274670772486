import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {queryParamsToObject} from "../DataTableUtils";
import {createBrowserHistory} from "history";

interface InputWrapperProps {
    name?: string;
    id?: string;
    type?: string;
    placeholder?: string;
    disabled?: boolean;
    reset?: Function;
    onChange?: Function;
    onKeyDown?: Function;
    className?: string;
    accessor: string;
    defaultValue?: any;

    label?: string;
}

interface ModalFilterOptions {
    onClick?: Function;
    showModal?: boolean;
    setShowModal?: Function
}

export const SearchInputWrapper: React.FC<InputWrapperProps> = (props: InputWrapperProps) => {

    const location: any = useLocation();

    const {
        name,
        id,
        placeholder,
        type,
        disabled,
        onChange,
        onKeyDown,
        accessor,
        defaultValue = null,
        label = null
    } = props;

    let [values, setValues] = useState(defaultValue);

    useEffect(() => {
        setValues(defaultValue !== null ? defaultValue : values);
    }, [defaultValue]);

    useEffect(() => {
        if (type === 'checkBox') {
            setValues(values === null ? defaultValue : values);
        }
    }, [values]);

    const handleChange = (value: any) => {
        if (type === 'checkBox') {
            setValues(value.target.checked);
        } else {
            setValues(value.target.value);
        }

        if (!onChange) return;
        onChange(value);
    };

    const handleKeyDown = (value: any) => {
        if (!onKeyDown) return;
        onKeyDown(value);
    };

    useEffect(() => {
        if (location.search === undefined || location.search !== '') {
            if (type === "checkBox") {
                setValues(values === null ? defaultValue : values);
            } else {
                setValues(null)
            }
        }
    }, [location.search]);

    useEffect(() => {
        if (location.search && location.search !== "" && (values === null || values === "")) {
            if (queryParamsToObject(location.search) instanceof Object && Object.keys(queryParamsToObject(location.search)).length > 0) {

                Object.keys(queryParamsToObject(location.search)).map((cur, i) => {
                    if (cur === accessor && !queryParamsToObject(location.search).hasOwnProperty(cur + "ShowValue")) {
                        // @ts-ignore
                        setValues(queryParamsToObject(location.search)[cur]);
                    }
                    if (cur === accessor && queryParamsToObject(location.search).hasOwnProperty(cur + "ShowValue")) {
                        // @ts-ignore
                        setValues(queryParamsToObject(location.search)[cur + "ShowValue"]);
                    }
                })
            }
        }
    }, []);

    return type !== "checkBox" ?
        <input id={id} type={type} name={name} value={values}
               placeholder={placeholder}
               className={"bg-surface-2 border border-surface-5 form_control p-2 placeholder:text-text-2 rounded-md text-text-1 w-full"}
               onChange={handleChange}
               onKeyDown={handleKeyDown}
               disabled={disabled}/>
        :
        <>
            <input className={"h-8 w-5"} id={id} type={type} value={values} name={name}
                   checked={values}
                   placeholder={placeholder}
                   onChange={handleChange}
                   onKeyDown={handleKeyDown}
                   disabled={disabled}/>
            <label className={"ml-2"}>
                {label}
            </label>
        </>
};