import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import Select from "react-select";
import {queryParamsToObject} from "../DataTableUtils";
import {selectStyle} from "../../../../helpers/utils/StyleUtils";

interface SelectWrapperProps {
    name?: string;
    accessor?: string;

    classNamePrefix: string;

    isMulti?: boolean;
    menuShouldBlockScroll?: boolean;
    menuShouldScrollIntoView?: boolean;
    tabSelectsValue?: boolean;
    backspaceRemovesValue?: boolean;
    defaultValue?: any,
    isConstantData?: boolean;

    options: { label: string, value: string }[];
    placeholder: string;
    onChange?: Function;
}

export const SearchSelectWrapper: React.FC<SelectWrapperProps> = (props: SelectWrapperProps) => {

    const location: any = useLocation();

    const {
        name = '',
        accessor = '',
        classNamePrefix,
        isMulti,
        menuShouldBlockScroll,
        menuShouldScrollIntoView,
        tabSelectsValue,
        backspaceRemovesValue,
        defaultValue,
        isConstantData = false,
        options,
        placeholder,
        onChange,
    } = props;

    let [values, setValues] = useState(defaultValue);
    const [optionState, setOptionState] = useState(null as any);


    //to prevent re-rendering of options multiple times when options are unChanged...
    useEffect(() => {
        if ((optionState === null || optionState === undefined) && isConstantData) {
            setOptionState(options);
        }
        if (!isConstantData) {
            setOptionState(options);
        }
    }, [options]);


    useEffect(() => {
        const queryParamsObject = queryParamsToObject(location.search);

        if (defaultValue !== null && values === null) {
            isMulti ?
                // @ts-ignore
                setValues((location.search && queryParamsObject)?.hasOwnProperty(accessor) ? queryParamsObject[accessor] : [defaultValue])
                :
                // @ts-ignore
                setValues((location.search && queryParamsToObject(location.search?.hasOwnProperty(accessor))) ? queryParamsObject[accessor] : defaultValue);
        }
    }, []);

    useEffect(() => {
        if (location.search && location.search !== "" && optionState !== undefined) {

            if (queryParamsToObject(location.search) instanceof Object && Object.keys(queryParamsToObject(location.search)).length > 0) {
                Object.keys(queryParamsToObject(location.search)).map((cur, i) => {
                    if (cur === accessor) {
                        //@ts-ignore
                        setValues(queryParamsToObject(location.search)[cur]);
                    }
                })
            }
        }
    }, [optionState]);


    const handleChange = (value: any) => {
        location.state = undefined;
        setValues(value && Array.isArray(value) ? value.map(subValue => subValue.value) : value?.value);

        if (!onChange) return;
        onChange(value && Array.isArray(value) ? value.map(subValue => subValue.value) : value?.value);
    };

    return <Select
        name={name}
        classNamePrefix={classNamePrefix}

        isMulti={isMulti}
        menuShouldBlockScroll={menuShouldBlockScroll}
        menuShouldScrollIntoView={menuShouldScrollIntoView}
        tabSelectsValue={tabSelectsValue}
        backspaceRemovesValue={backspaceRemovesValue}
        blurInputOnSelect={!isMulti}
        isClearable
        //@ts-ignore
        options={optionState}
        placeholder={placeholder}
        onChange={handleChange}
        styles={selectStyle}
        isDisabled={!optionState || !optionState.length}
        value={getObjectiveDataWithMatchedData(isMulti, optionState, values, "value")}
    />;
};

//@ts-ignore
function getObjectiveDataWithMatchedData(isMulti, options, name, valueToReturn) {

    if (name === null || name === "") {
        return null;
    }

    if (isMulti && Array.isArray(options) && options.length > 0 && Array.isArray(name)) {
        return options.filter(cur => name.indexOf(cur[valueToReturn]) >= 0).length === 0 ? null : options.filter(cur => name.indexOf(cur[valueToReturn]) >= 0);
    }

    if (!isMulti && Array.isArray(options) && options.length > 0 && name !== null) {
        return options.find(cur => cur[valueToReturn] === name);
    }
}

//@ts-ignore
export function SearchSelectWithEnum(enumMap, placeholderText, isMulti, accessor, defaultValue = null, quickFilters = null, isConstantData = false) {
    return SearchSelectWithList(enumMap, "displayName", "constant", placeholderText, isMulti, accessor, defaultValue, quickFilters, isConstantData);
}

// Label/value can be either a Function or a string
// This is useful when the label/value are built based on other things, like 'CustomerName (CustomerNumber)'
//@ts-ignore
export function SearchSelectWithList(itemMap, label, value, placeholderText, isMulti, accessor, defaultValue = null, quickFilters = null, isConstantData = false) {
    const options = itemMap && Array.isArray(itemMap) && itemMap?.map(item => {
        return {
            value: value instanceof Function ? value(item) : item[value],
            label: label instanceof Function ? label(item) : item[label]
        }
    });

    return <SearchSelectWrapper
        classNamePrefix="select"
        accessor={accessor}
        isConstantData={isConstantData}
        isMulti={isMulti}
        menuShouldBlockScroll
        menuShouldScrollIntoView
        tabSelectsValue

        options={options}
        placeholder={placeholderText}
        defaultValue={defaultValue}
    />;
}

