import React, {FormEvent, useEffect, useState} from "react";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {createClickableLink} from "../../../components/common/datatable/DataTableUtils";
import {SearchInputWrapper} from "../../../components/common/datatable/filter/SearchInputWrapper";
import {DataTable} from "../../../components/common/datatable/DataTable";
import IconButton from "../../../components/common/form/IconButton";
import {FaPlus, FaTrash} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {useFindUserSettingsByGroupNameQuery} from "../../../components/common/userSetting/userSettingsApi";
import {
    useDeletePartnerPricingByIdMutation,
    useLazyExportPartnerPricingDataQuery,
    useSearchPartnerPricingQuery
} from "./partnerPricingApi";
import ModalButton from "../../../components/common/form/ModalButton";
import ConfirmationModal from "../../../components/layout/modal/ConfirmationModal";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {
    SearchSelectWithEnum,
    SearchSelectWithList
} from "../../../components/common/datatable/filter/SearchSelectWrapper";
import {useGetAllStatesQuery} from "../../data/states/statesApi";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";

const PartnerPricing = () => {

    const navigate = useNavigate()

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Partner Pricing", url: ""},
    ]);

    useEffect(() => {
        document.title = "Partner Pricing";
    });

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("Partner Pricing");
    const {data: enums} = useGetEnumsQuery("");
    const statesQueryApi = useGetAllStatesQuery("");
    const [deletePricingApi, {isLoading: deletePricingApiResponse}] = useDeletePartnerPricingByIdMutation();
    const [pricingId, setPricingId] = useState("");
    const [triggerExportPartnerPricingDataQuery] = useLazyExportPartnerPricingDataQuery();

    const handleDeletePricingSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (pricingId) {
            return deletePricingApi(pricingId);
        }
    }


    const columns: DataTableColumn[] = [
        {
            label: "Description",
            accessor: "description",
            cell: (props: any) => {
                let path = `/accounting/partner-pricing/${props.row.original.id}`;
                return createClickableLink(path, props.value, userSettings, false, "", ["PTPRC-R", "PTPRC-V"]);
            },
            filterOptions: [{
                filterComponent: <SearchInputWrapper type={"text"} placeholder={"Enter Description..."}
                                                     name={"description"}
                                                     accessor={"description"}/>,
                overrideFilterLabel: "Name"
            }]
        },
        {
            label: "Product",
            accessor: "productType",
            filterOptions: [{
                filterComponent: SearchSelectWithEnum(enums?.["ProductType"], "Select Product Type...", false, "productType", null),
            }]
        },

        {
            label: "Partner",
            accessor: "partner",
            filterOptions: [{
                filterComponent: SearchSelectWithEnum(enums?.["OrderSource"], "Select Partner...", false, "partner", null),
            }]
        },

        {
            label: "State",
            accessor: "state.stateName",
            filterOptions: [{
                filterComponent: statesQueryApi?.data ? SearchSelectWithList(statesQueryApi?.data, (state: any) => `${state.stateName}`, "id", "Select Select...", false, "stateId") : [],
            }]
        },

        {
            label: "County",
            accessor: "county.name"
        },
        {
            label: "MSA",
            accessor: "metroArea.name"
        },
        {
            label: "Cost",
            accessor: "partnerCost"
        },
        {
            label: "Action",
            visibility: {
                canColumnShow: hasPermission(["PTPRC-D"]),
                showByDefault: hasPermission(["PTPRC-D"]),
                hasShowSetting: false,
            },
            accessor: "systemGenerated",
            canSort: false,
            cell: (props: any) => {
                return <>
                    {!props.row.original.systemGenerated ? <div className="ml-4">
                        <ModalButton
                            triggerButton={
                                <FaTrash
                                    className={"cursor-pointer text-highlight-3 text-lg hover:text-highlight-7"}
                                    title={"Delete Partner Pricing"}/>
                            }
                            onOpen={() => {
                                setPricingId(props.row.original.id)
                            }}
                            modal={
                                <ConfirmationModal
                                    show={true}
                                    setShow={() => {
                                    }}
                                    title={"Delete Partner Pricing"}
                                    bodyMessage={"Are you sure you want to delete this partner pricing ?"}
                                    onSubmit={handleDeletePricingSubmit}
                                    isLoading={deletePricingApiResponse}
                                />
                            }
                        />
                    </div> : <></>}
                </>
            }
        }

    ]

    return (
        <div className="user-container px-5 py-5">
            <div className="flex justify-between">
                <Breadcrumb crumbs={crumbs}/>
            </div>

            <DataTable
                columns={columns}
                actions={{
                    fetchAction: useSearchPartnerPricingQuery,
                    exportAction: triggerExportPartnerPricingDataQuery
                }}
                userSettingGroup={"Partner Pricing"}
                buttonOptions={{
                    addButton:
                        {
                            button: <IconButton
                                icon={<FaPlus/>}
                                hasBackground={true}
                                onClick={() => {
                                    navigate("/accounting/partner-pricing/create")
                                }}
                                title={"Create Partner Pricing"}
                            />,
                            permissions: ["PTPRC-C"]
                        },
                }}
                basePermission={"PTPRC"}
            />


        </div>
    )
}
export default PartnerPricing;