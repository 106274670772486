import React, {FC, useState} from "react"
import {FaLock} from "react-icons/fa6"
import Breadcrumb from "../../components/common/Breadcrumbs";
import ResetPassword from "./ResetPassword";
import {useFindUserProfileInfoQuery} from "./profileApi";
import InputField from "../../components/common/form/InputField";
import ModalButton from "../../components/common/form/ModalButton";
import IconButton from "../../components/common/form/IconButton";
import {hasPermission} from "../../helpers/utils/AccessControlUtils";

const Profile: FC = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Profile", url: "/profile"},

    ])

    const {data: userProfileInfo} = useFindUserProfileInfoQuery("");

    return (
        <>
            <div className="p-5">
                <div className="justify-between sm:block md:flex lg:flex">
                    <Breadcrumb crumbs={crumbs}/>

                    {hasPermission(["SPWRE-V"]) && <ModalButton
                        triggerButton={
                            <IconButton icon={<FaLock/>} label={"Reset Password"} title={"Reset Password"}/>
                        }
                        modal={
                            <ResetPassword show={true} setShow={() => {
                            }}/>
                        }
                    />}

                </div>

                <div className=" flex justify-center mt-3">
                    <div className=" bg-surface-2 lg:w-4/6 sm:w-full p-5 rounded-md">
                        <h3 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3">Profile
                            Info</h3>
                        <form>
                            <div className=" grid-cols-12 mt-6 sm:block md:grid lg:grid">

                                <InputField
                                    label="Username"
                                    value={userProfileInfo?.username || ""}
                                    type="text"
                                    placeholder="Enter Username..."
                                    disabled={true}
                                    className={"sm:mt-3 md:mt-0 lg:mt-0"}
                                />

                                <InputField
                                    label="Email"
                                    value={userProfileInfo?.email || ""}
                                    type="email"
                                    placeholder="Enter Email..."
                                    disabled={true}
                                />
                                <InputField
                                    label="Customer"
                                    value={userProfileInfo?.customer?.customerName || ""}
                                    placeholder="Customer..."
                                    type="text"
                                    disabled={true}
                                />
                                <InputField
                                    label="Branch"
                                    value={userProfileInfo?.branch?.name || ""}
                                    placeholder="Branch..."
                                    type="text"
                                    disabled={true}
                                />
                            </div>

                        </form>
                    </div>
                </div>

            </div>
        </>
    )
}
export default Profile