import {useParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {skipToken} from "@reduxjs/toolkit/query";
import {useGetCustomerByIdQuery} from "../customerApi";
import Breadcrumb from "../../../../components/common/Breadcrumbs";
import BranchForm from "./BranchForm";
import ContactTab from "../branchContact/ContactTab";
import {Tabs} from "react-simple-tabs-component";
import {hasPermission} from "../../../../helpers/utils/AccessControlUtils";
import {fetchWithExtraOptions} from "../../../../app/strApi";
import {strApi} from "../../../../app/strApi";
import {useGetBranchByIdQuery} from "../branchApi";

const BranchDetailPage = () => {

    let {customerId} = useParams();
    let {branchId} = useParams();

    const [crumbs, setCrumbs] = useState<any[]>([]);

    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getBranchById, {branchId}, {
        redirectToErrorPage: true, skip: !branchId
    });

    const branchQuery = useGetBranchByIdQuery({branchId});
    const customerQuery = useGetCustomerByIdQuery(customerId ?? skipToken);

    useEffect(() => {
        const branchObj = branchQuery?.data;
        const customerObj = customerQuery?.data;
        if (branchObj && customerObj) {
            setCrumbs([
                {content: "Home", url: "/home"},
                {content: "Customers", url: "/access-control/customers"},
                {content: customerObj?.customerName, url: `/access-control/customers/${customerId}`},
                {content: branchObj?.name, url: ""}
            ]);

            document.title = `${branchObj?.name}`;
        }
    }, [branchQuery?.data, customerQuery?.data]);


    // Compute tabs using useMemo
    const tabs = useMemo(() => {
        const result: any[] = [];

        result.push({
            label: 'Contacts',
            Component: ContactTab
        });

        return result;
    }, []);

    return (
        <>
            <div className="px-4 py-2">
                <Breadcrumb crumbs={crumbs}/>

                <div className="grid grid-cols-2 mt-3">
                    <div className={"bg-surface-2 p-4 rounded-md"}>
                        <div className="flex gap-2 items-center">
                            <h2 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3 w-full">Update
                                Branch
                            </h2>
                        </div>

                        <BranchForm customerId={customerId} branchId={branchId} btnText='Update'
                                    cancelBtnText='Cancel' isEditable={hasPermission(["BRAN-U"])}/>

                    </div>
                    <div className={"ml-3 rounded-md"}>
                        <Tabs tabs={tabs} className="bootstrap-tabs-component"/>
                    </div>
                </div>

            </div>

        </>
    )
}
export default BranchDetailPage;