import {useParams} from "react-router-dom";
import ModalButton from "../../../../components/common/form/ModalButton";
import {FaPlus, FaTrash} from "react-icons/fa6";
import {DataTable} from "../../../../components/common/datatable/DataTable";
import React, {FormEvent, useState} from "react";
import {useRemoveLeaderFromTeamMutation, useTeamLeadersQuery} from "../teamsApi";
import {hasPermission} from "../../../../helpers/utils/AccessControlUtils";
import ConfirmationModal from "../../../../components/layout/modal/ConfirmationModal";
import AddLeader from "../modal/AddLeader";
import {DataTableColumn} from "../../../../components/common/datatable/DataTableTypes";

const LeaderTab = () => {

    let {teamId} = useParams();
    const [selectedLeaderId, setSelectedLeaderId] = useState("");
    const [deleteLeaderApi, {isLoading: deleteLeaderApiResponse}] = useRemoveLeaderFromTeamMutation();

    const handleDeleteLeaderSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (selectedLeaderId) {
            return deleteLeaderApi({teamId: teamId, userId: selectedLeaderId});
        }
    }

    const columns: DataTableColumn[] = [
        {
            label: "Name",
            accessor: "username",
        },
        {
            label: "Email",
            accessor: "email"
        },
        {
            label: "Action",
            visibility: {
                canColumnShow: hasPermission(["TEAM-U"]),
                showByDefault: hasPermission(["TEAM-U"]),
                hasShowSetting: false,
            },
            accessor: "creationDate",
            canSort: false,
            cell: (props: any) => {
                return <>
                    <div className="ml-4">
                        <ModalButton
                            triggerButton={
                                <FaTrash
                                    className={"cursor-pointer text-highlight-3 text-lg hover:text-highlight-7"}
                                    title={"Delete Leader"}/>
                            }
                            onOpen={() => {
                                setSelectedLeaderId(props.row.original.id)
                            }}
                            modal={
                                <ConfirmationModal
                                    show={true}
                                    setShow={() => {
                                    }}
                                    title={"Delete Leader"}
                                    bodyMessage={`Are you sure you want to remove ${props.row.original.username} from the leader list ?`}
                                    onSubmit={handleDeleteLeaderSubmit}
                                    isLoading={deleteLeaderApiResponse}
                                />
                            }
                        />
                    </div>
                </>
            }
        }

    ]

    return (
        <>

            <div className={"flex justify-end"}>
                {hasPermission(["TEAM-U"]) &&
                    <div
                        className="h-7 w-10 text-text-1 rounded-md bg-highlight-3 flex justify-center items-center cursor-pointer hover:bg-highlight-7 add_icon_outer">
                        <ModalButton
                            triggerButton={<FaPlus className="text-inherit"/>}
                            onOpen={() => {
                            }}
                            modal={
                                <AddLeader
                                    show={true}
                                    setShow={() => {
                                    }}
                                    teamId={teamId}
                                />
                            }
                        />

                    </div>
                }
            </div>

            <div className='-mt-4'>
                <DataTable
                    columns={columns}
                    actions={{
                        fetchAction: useTeamLeadersQuery
                    }}
                    defaultFilter={{
                        teamId: teamId
                    }}
                    userSettingGroup={"Team Leader"}
                    appendFilterToUrl={false}
                />
            </div>

        </>
    )
}

export default LeaderTab;