import React, {FC, FormEvent, useMemo, useState} from "react";
import {FaEye, FaEyeSlash, FaFilter, FaShareAlt, FaToggleOff, FaToggleOn} from "react-icons/fa";
import Select from "react-select";
import {createOptionListForSelectTag} from "../../../helpers/utils/Utils";
import {selectStyle} from "../../../helpers/utils/StyleUtils";
import {useGetEnumsQuery} from "../../../app/globalApi";
import SkeletonLoader from "../../../components/common/SkeletonLoader";
import {skipToken} from "@reduxjs/toolkit/query";
import {useDispatch} from "react-redux";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {strApi} from "../../../app/strApi";
import ConfirmationModal from "../../../components/layout/modal/ConfirmationModal";
import {
    FaCircleInfo,
    FaDownload,
    FaFileCircleMinus,
    FaFileCirclePlus, FaRotateRight,
    FaTrash,
    FaTrashCanArrowUp, FaUpload
} from "react-icons/fa6";
import {getFileSizeFromBytes, toTitleCase, truncateText} from "../../../helpers/utils/TextUtils";
import SimplifileLinkButton from "./SimplifileLinkButton";
import {AuthTokenPage} from "../AuthTokenPage";
import AuditableInfo from "../../../components/common/AuditableInfo";
import ModalButton from "../../../components/common/form/ModalButton";
import VendorInfoModal from "../modal/VendorInfoModal";
import {
    useDeleteDocumentMutation, useGetProductDocumentListQuery, useLazyViewFileQuery,
    useRestoreDocumentMutation,
    useUpdateDocumentVisibilityMutation
} from "../orderFilesApi";
import {useRemoveDocumentMutation, useUpdateDocumentMutation} from "../recordingDocumentApi";
import AddDocumentToPackageModal from "../modal/AddDocumentToPackageModal";
import {useGetRecordingPackageByProductIdQuery} from "../recordingPackageApi";
import UploadDocumentModal from "../modal/UploadDocumentModal";
import {useParams} from "react-router-dom";
import DocumentDeliverModal from "../modal/DocumentDeliverModal";
import {useFetchSimplifileTokenStatusQuery} from "../../user/users/usersApi";
import {hasPermission, jwtDecode} from "../../../helpers/utils/AccessControlUtils";
import ButtonTray from "../../../components/common/form/ButtonTray";

interface props {
    productId?: string | null,
    orderQuery: any
}

const ProductDocuments: FC<props> = ({productId, orderQuery}) => {

    const dispatch = useDispatch();

    const {orderId} = useParams();

    const [show, setShowFilterList] = useState(false);
    const [includeDeletedFiles, setIncludeDeletedFiles] = useState(false);
    const {data: enums} = useGetEnumsQuery("")
    const [selectType, setSelectType] = useState<any[]>([])
    const [selectedFileId, setSelectedFileId] = useState("");
    const [selectedRecordedDocId, setSelectedRecordedDocId] = useState("");
    const simplifileTokenApi = useFetchSimplifileTokenStatusQuery(jwtDecode()?.sub ?? skipToken);

    const handleChange = (selectedOption: any[]) => {
        const selectedOptions = selectedOption?.map(c => c.value) || []
        setSelectType(selectedOptions)
    };

    const [updateDocumentVisibility, {isLoading: isUpdateLoading}] = useUpdateDocumentVisibilityMutation()
    const [deleteDocument, {isLoading: isDeleteLoading}] = useDeleteDocumentMutation();
    const [restoreDocument, {isLoading: isRestoreLoading}] = useRestoreDocumentMutation();
    const [removeDocument, {isLoading: isRemoveLoading}] = useRemoveDocumentMutation();
    const [updateDocument, {isLoading: isUpdateFromSimplifileLoading}] = useUpdateDocumentMutation();
    const [viewFile] = useLazyViewFileQuery();

    const handleViewDownloadFile = async (fileToDownloadId: string, isDownload: boolean) => {
        viewFile({fileId: fileToDownloadId, isDownload: isDownload})
    }

    const handleUpdateVisibilitySubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await handleRTKQuery(
            async () => {
                return await updateDocumentVisibility({id: selectedFileId}).unwrap();
            },
            () => {
                dispatch(strApi.util.invalidateTags(["productDocumentList"]))
            }
        );
    }

    const handleDocumentDeleteSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        return deleteDocument({id: selectedFileId});
    }

    const handleDocumentRestoreSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        return restoreDocument({id: selectedFileId});
    }

    const handleRemoveDocumentSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        return removeDocument(selectedRecordedDocId);
    }

    const handleUpdateDocumentSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        return updateDocument(selectedRecordedDocId);
    }

    const {
        data: documents,
        isLoading: loading,
        isError: error,

    } = useGetProductDocumentListQuery(productId || includeDeletedFiles ? {
        productId: productId,
        orderFileType: selectType,
        includeDeleted: includeDeletedFiles
    } : skipToken);

    const {
        data: recordingPackageData,
    } = useGetRecordingPackageByProductIdQuery(productId ?? skipToken);

    const iconButtonStyle = "cursor-pointer text-highlight-3 text-lg hover:text-highlight-7";

    const filterOptionList = useMemo(() => createOptionListForSelectTag(enums?.OrderFileType, "displayName", "constant"),
        [enums]);


    return (
        <>
            <div className="bg-surface-2 rounded-md p-3 mt-3">
                <div className="flex justify-between gap-2 items-center border-b border-surface-3 pb-2">
                    <div className="text-text-1 font-semibold flex gap-3">
                        Documents ({documents?.length || 0})
                        <div className={"mt-1"}>
                            <FaFilter className={iconButtonStyle} onClick={() => {
                                setShowFilterList(!show)
                            }}/>
                        </div>
                    </div>

                    <ButtonTray
                        buttons={[
                            {
                                buttonProps: {
                                    icon: <FaToggleOn/>,
                                    onClick: () => {
                                        setIncludeDeletedFiles(!includeDeletedFiles);
                                    },
                                    hasBackground: false,
                                    title: 'Hide Deleted Files',
                                    isVisible: hasPermission(["DELFILE-V"]) && includeDeletedFiles
                                },
                                buttonType: "icon",
                            },
                            {
                                buttonProps: {
                                    icon: <FaToggleOff/>,
                                    onClick: () => {
                                        setIncludeDeletedFiles(!includeDeletedFiles);
                                    },
                                    hasBackground: false,
                                    title: 'Show Deleted Files',
                                    isVisible: hasPermission(["DELFILE-V"]) && !includeDeletedFiles
                                },
                                buttonType: "icon",
                            },
                            {
                                buttonProps: {
                                    modal: <DocumentDeliverModal
                                        show={true}
                                        setShow={() => {
                                        }}
                                        productId={productId}
                                        orderId={orderId}
                                        orderQuery={orderQuery}
                                    />,
                                    onOpen: () => {
                                    },
                                    triggerButton: <FaShareAlt className={iconButtonStyle} title='Deliver documents'/>,
                                    isVisible: hasPermission(["FILEDELIVER-V"])
                                },
                                buttonType: "modal",
                            },
                            {
                                buttonProps: {
                                    modal: <UploadDocumentModal
                                        show={true}
                                        setShow={() => {
                                        }}
                                        productId={productId}
                                        orderId={orderId}
                                    />,
                                    onOpen: () => {
                                    },
                                    triggerButton: <FaUpload className={iconButtonStyle} title='Upload documents'/>
                                    ,
                                    isVisible: hasPermission(["FILEUPLOAD-V"])
                                },
                                buttonType: "modal",
                            }
                        ]}
                        align={"end"}
                        gap={4}
                    />

                </div>

                {
                    show && <Select
                        placeholder='Select filter options...'
                        isMulti={true}
                        options={filterOptionList}
                        value={filterOptionList.filter((option: any) => selectType.includes(option.value))}
                        //@ts-ignore
                        onChange={handleChange}
                        styles={selectStyle}
                    />
                }
                <div className={'overflow-y-auto max-h-96'}>
                    {
                        loading ?
                            <SkeletonLoader className={"mt-2"} count={3}/> : error && !documents ?
                                <div className="mt-2 border border-surface-3 p-4 rounded-sm">
                                    <h3 className="text-text-1">
                                        Unable to retrieve product documents, please try again in a few minutes
                                    </h3>
                                </div>
                                : documents?.length === 0 ?
                                    <div className="mt-2 border border-surface-3 p-4 rounded-sm">
                                        <h3 className="text-text-1">
                                            No product documents to display
                                        </h3>
                                    </div> :
                                    documents?.map((data: any, index: number) => {
                                        return <div
                                            key={index}
                                            //  TODO: Maybe have a separate color category for INFO/success/happy kind of stuff, like we do for error
                                            className={`${data?.delivered && 'border-l-highlight-2'} sm:block md:flex justify-between mt-3 border-l-8 border-t border-b border-r rounded-sm border-surface-3 py-1 px-2 ${data?.deleted && "bg-surface-1"}`}>

                                            {/*Start Visibility*/}
                                            <div className="sm:block md:flex gap-2 items-start relative">
                                                <div className={"mt-1"}>
                                                    {hasPermission(["FILE-U"]) && <ModalButton
                                                        triggerButton={data?.visibility ?
                                                            <FaEye className={iconButtonStyle}/> :
                                                            <FaEyeSlash
                                                                className={iconButtonStyle}/>}
                                                        onOpen={() => {
                                                            setSelectedFileId(data?.id)
                                                        }}
                                                        modal={
                                                            <ConfirmationModal
                                                                show={true}
                                                                setShow={() => {
                                                                }}
                                                                title={"Change Product Document Visibility"}
                                                                bodyMessage={data?.visibility ? "Are you sure you want to make this document hidden to the customers ?" : "Are you sure you want to make this document visible to the customers ?"}
                                                                onSubmit={handleUpdateVisibilitySubmit}
                                                                isLoading={isUpdateLoading}
                                                            />
                                                        }
                                                    />}

                                                </div>
                                                <div>
                                                    <div
                                                        className="text-ellipsis cursor-pointer text-highlight-6 hover:text-highlight-7"
                                                        onClick={() => {
                                                            handleViewDownloadFile(data.id, false).then()
                                                        }} title={data?.fileName}>{truncateText(data?.fileName, 40)}</div>
                                                    <div
                                                        className="text-text-2 italic">{data?.fileType} ({getFileSizeFromBytes(data?.fileSize, 0)})
                                                    </div>

                                                    {data?.recordingDocument?.vendorDetails &&
                                                        <div className="flex gap-1 items-center mt-1">
                                                            <h3 className="text-text-2 italic">{toTitleCase(data?.recordingDocument?.vendorDetails?.vendorStatus)}</h3>

                                                            <ModalButton
                                                                triggerButton={
                                                                    <FaCircleInfo title='View vendor information'
                                                                                  className={iconButtonStyle}/>
                                                                }
                                                                modal={
                                                                    <VendorInfoModal
                                                                        show={true}
                                                                        setShow={() => {
                                                                        }}
                                                                        recordingDocument={data?.recordingDocument}
                                                                    />
                                                                }
                                                            />
                                                        </div>}


                                                    <div className="flex mt-1 top-auto">

                                                        {data?.recordingDocument?.vendorDetails?.vendorTransactionInfo &&
                                                            <div className={"mr-3"}>
                                                                <SimplifileLinkButton
                                                                    className={iconButtonStyle}
                                                                    page={AuthTokenPage.VIEW_DOCUMENT}
                                                                    objectId={data?.recordingDocument?.vendorDetails?.vendorTransactionInfo?.vendorObjectId}
                                                                    tenantId={recordingPackageData?.tenantId}/>
                                                            </div>
                                                        }

                                                        <ButtonTray
                                                            buttons={[
                                                                {
                                                                    buttonProps: {
                                                                        modal: <AddDocumentToPackageModal
                                                                            show={true}
                                                                            fileId={data?.id}
                                                                            packageId={recordingPackageData?.id}
                                                                            recipientId={recordingPackageData?.vendorDetails?.vendorRecipientId}
                                                                            setShow={() => {
                                                                            }}
                                                                            tenantId={orderQuery?.data?.tenantId}
                                                                            productId={productId}
                                                                        />,
                                                                        onOpen: () => {
                                                                        },
                                                                        triggerButton: <FaFileCirclePlus
                                                                            title='Add this document to the package'
                                                                            className={iconButtonStyle}/>,
                                                                        isVisible: simplifileTokenApi?.data && !data?.recordingDocument?.vendorDetails?.vendorTransactionInfo && recordingPackageData?.vendorDetails?.vendorStatus !== "RECORDED"
                                                                    },
                                                                    buttonType: "modal",
                                                                },
                                                                {
                                                                    buttonProps: {
                                                                        modal: <ConfirmationModal
                                                                            show={true}
                                                                            setShow={() => {
                                                                            }}
                                                                            title={"Remove document from the package"}
                                                                            bodyMessage={"Are you sure you want to remove this document from the package?"}
                                                                            onSubmit={handleRemoveDocumentSubmit}
                                                                            isLoading={isRemoveLoading}
                                                                        />,
                                                                        onOpen: () => {
                                                                            setSelectedRecordedDocId(data?.recordingDocument?.id)
                                                                        },
                                                                        triggerButton: <FaFileCircleMinus
                                                                            title='Remove this document from the package'
                                                                            className={iconButtonStyle}/>,
                                                                        isVisible: simplifileTokenApi?.data && data?.recordingDocument?.vendorDetails?.vendorTransactionInfo && recordingPackageData?.vendorDetails?.vendorStatus !== "RECORDED"
                                                                    },
                                                                    buttonType: "modal",
                                                                },
                                                                {
                                                                    buttonProps: {
                                                                        modal: <ConfirmationModal
                                                                            show={true}
                                                                            setShow={() => {
                                                                            }}
                                                                            title={"Update document From SimpliFile"}
                                                                            bodyMessage={"Are you sure you want to update this document from SimpliFile?"}
                                                                            onSubmit={handleUpdateDocumentSubmit}
                                                                            isLoading={isUpdateFromSimplifileLoading}
                                                                        />,
                                                                        onOpen: () => {
                                                                            setSelectedRecordedDocId(data?.recordingDocument?.id)
                                                                        },
                                                                        triggerButton: <FaRotateRight
                                                                            title='Update this document from SimpliFile'
                                                                            className={`${iconButtonStyle}`}/>,
                                                                        isVisible: simplifileTokenApi?.data && data?.recordingDocument?.vendorDetails?.vendorTransactionInfo
                                                                    },
                                                                    buttonType: "modal",
                                                                }
                                                            ]}
                                                            align={"end"}
                                                            gap={2}
                                                        />

                                                    </div>

                                                </div>
                                            </div>
                                            {/*End Visibility*/}

                                            <div>
                                                <AuditableInfo data={data}/>

                                                <div className={"md:mt-16 sm:mt-6"}>
                                                    <ButtonTray
                                                        buttons={[
                                                            {
                                                                buttonProps: {
                                                                    icon: <FaDownload/>,
                                                                    onClick: () => {
                                                                        handleViewDownloadFile(data.id, true).then();
                                                                    },
                                                                    hasBackground: false,
                                                                    title: "Download document",
                                                                },
                                                                buttonType: "icon",
                                                            },
                                                            {
                                                                buttonProps: {
                                                                    modal: <ConfirmationModal
                                                                        show={true}
                                                                        setShow={() => {
                                                                        }}
                                                                        title={"Delete Document"}
                                                                        bodyMessage={"Are you sure you want to delete this document ?"}
                                                                        onSubmit={handleDocumentDeleteSubmit}
                                                                        isLoading={isDeleteLoading}
                                                                    />,
                                                                    onOpen: () => {
                                                                        setSelectedFileId(data?.id)
                                                                    },
                                                                    triggerButton: <FaTrash className={iconButtonStyle}
                                                                                            title={"Delete document"}/>,
                                                                    isVisible: !data?.deleted && hasPermission(["FILE-D"])
                                                                },
                                                                buttonType: "modal",
                                                            },
                                                            {
                                                                buttonProps: {
                                                                    modal: <ConfirmationModal
                                                                        show={true}
                                                                        setShow={() => {
                                                                        }}
                                                                        title={"Restore Document"}
                                                                        bodyMessage={"Are you sure you want to restore this product document ?"}
                                                                        onSubmit={handleDocumentRestoreSubmit}
                                                                        isLoading={isRestoreLoading}
                                                                    />,
                                                                    onOpen: () => {
                                                                        setSelectedFileId(data?.id)
                                                                    },
                                                                    triggerButton: <FaTrashCanArrowUp
                                                                        className={iconButtonStyle}
                                                                        title={"Restore document"}/>,
                                                                    isVisible: data?.deleted && hasPermission(["FILE-U"])
                                                                },
                                                                buttonType: "modal",
                                                            }
                                                        ]}
                                                        align={"end"}
                                                        gap={4}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    })}
                </div>
            </div>
        </>
    )
}
export default ProductDocuments;
