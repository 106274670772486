import {strApi} from "../../app/strApi";
import {serialize} from "../../helpers/utils/Utils";
import {getAuthorizationHeader, handleQueryError, handleQueryErrorAndSuccess} from "../../helpers/utils/RtkQueryUtils";
import {downloadFile, openFileInFrame, openFileInNewTab} from "../../helpers/utils/FileUtils";

export const orderFilesApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        searchDocument: build.query({
            query: (query) => {
                let paramData = serialize(query);
                return {
                    url: `/order-files/search?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        getProductDocumentList: build.query({
            query: ({productId, orderFileType, includeDeleted}) => {
                return {
                    url: `/order-files/productId/${productId}?
                    ${serialize(
                        {
                            orderFileType: orderFileType,
                            includeDeleted: includeDeleted
                        }
                    )}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ["productDocumentList"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        updateDocumentVisibility: build.mutation({
            query: ({id}) => {
                return {
                    url: `/order-files/${id}/visibility`,
                    method: 'POST',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Document Visibility")
            },
            invalidatesTags: ['productDocumentList']

        }),
        deleteDocument: build.mutation({
            query: ({id}) => {
                return {
                    url: `/order-files/${id}`,
                    method: 'DELETE',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Deleted", "Document")
            },
            invalidatesTags: ['productDocumentList']

        }),
        restoreDocument: build.mutation({
            query: ({id}) => {
                return {
                    url: `/order-files/${id}/restore`,
                    method: 'PUT',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Restore", "Document")
            },
            invalidatesTags: ['productDocumentList']
        }),
        viewFile: build.query({
            query: ({fileId, isDownload, isOpenInFrame}) => {
                return {
                    url: `/order-files/read/${fileId}?isDownload=${isDownload}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                    responseHandler: async (response: any) => {
                        const blob = await response.blob();
                        if (!isOpenInFrame) {
                            const contentDisposition = response.headers.get('Content-Disposition');
                            const filename = contentDisposition
                                ? contentDisposition.split('filename=')[1]
                                : `file_${fileId}.pdf`;

                            isDownload ? downloadFile(blob, filename) : openFileInNewTab(blob)
                        } else {
                            openFileInFrame(blob);
                        }
                        return {success: true};
                    },
                };

            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        uploadFile: build.mutation({
            query: ({file, orderFileDTO}) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('orderFilesData', JSON.stringify(orderFileDTO));

                return {
                    url: `/order-files/upload`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: formData,
                };
            },
            async onQueryStarted(_, {queryFulfilled}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Uploaded", "File");
            },
            invalidatesTags: ['productDocumentList'],
        }),

        deliverFile: build.mutation({
            query: ({orderId, deliveryEmail, files}) => {
                return {
                    url: `/order-files/deliver-file/${orderId}?${serialize({deliveryEmail: deliveryEmail})}`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: files
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Deleted", "File")
            },
            invalidatesTags: ['productDocumentList']

        }),

        generateInvoicePdf: build.mutation({
            query: ({productId, body}) => {

                return {
                    url: `/order-files/generate/invoice/custom/${productId}`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: body,
                };
            },
            async onQueryStarted(_, {queryFulfilled}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Generated", "Invoice");
            },
            invalidatesTags: ['productDocumentList'],
        }),
    }),

});

export const {
    useGetProductDocumentListQuery,
    useLazyViewFileQuery,
    useUpdateDocumentVisibilityMutation,
    useDeleteDocumentMutation,
    useRestoreDocumentMutation,
    useUploadFileMutation,
    useSearchDocumentQuery,
    useDeliverFileMutation,
    useGenerateInvoicePdfMutation
} = orderFilesApi;
