// @ts-ignore
import moment from "moment/moment";
// @ts-ignore
import _ from "lodash";
import {Link} from "react-router-dom";
import {isNullOrEmpty} from "../../../helpers/utils/Utils";
import {DataTableColumn, FilterOptions} from "./DataTableTypes";
import {UserSetting} from "../userSetting/UserSettingTypes";
import {SearchSelectWithList} from "./filter/SearchSelectWrapper";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import React from "react";

// @ts-ignore
export function splitArrayIntoChunks(value, chunkSize) {
    // @ts-ignore
    return value.reduce((all, one, i) => {
        const chunkIndex = Math.floor(i / chunkSize);
        all[chunkIndex] = [].concat((all[chunkIndex] || []), one);

        return all;
    }, [])
}

// Returns the given phoneNumber with all non-digits removed
export function getUnformattedPhoneNumber(phoneNumber: string) {
    return phoneNumber ? phoneNumber.replace('(', '').replace(')', '').replace('-', '').replace(' ', '') : null;
}

// @ts-ignore
export function defaultDateParamIfNotProvided(queryObj) {

    const creationDateFromKey = 'createdDateFrom';
    const today = new Date();
    const oneMonthAgo = new Date();
    const creationDateFromValue = moment(oneMonthAgo.setMonth(today.getMonth() - 1)).format("yyyy-MM-DD HH:mm:ss").toUpperCase();

    const creationDateToKey = 'createdDateTo';
    const creationDateToValue = moment().format("yyyy-MM-DD HH:mm:ss").toUpperCase();

    // @ts-ignore
    queryObj[creationDateFromKey] === undefined && _.setWith(queryObj, creationDateFromKey, creationDateFromValue, (existingValue, key, obj) => {
        if (_.has(obj, key)) {
            return existingValue;
        } else {
            return creationDateFromValue;
        }

    });

    // @ts-ignore
    queryObj[creationDateToKey] === undefined && _.setWith(queryObj, creationDateToKey, creationDateToValue, (existingValue, key, obj) => {
        if (_.has(obj, key)) {
            return existingValue;
        } else {
            return creationDateToValue;
        }

    });


    return queryObj;

}

// @ts-ignore
export function queryParamsToObject(input) {
    const payload = {};
    const params = new URLSearchParams(input);

    for (let [key, val] of params.entries()) {
        if (key.endsWith('[]')) {
            key = key.replace(/\[\]$/, '');
            // @ts-ignore
            if (payload[key]) {
                // @ts-ignore
                payload[key].push(val);
            } else {
                // @ts-ignore
                payload[key] = [val]
            }
        } else {
            if (val === 'true' || val === 'false') {
                // @ts-ignore
                payload[key] = val === 'true';
            } else {
                // @ts-ignore
                payload[key] = val;
            }
        }
    }

    return payload;
}

export function objectToQueryParams(input: Object) {
    const params = new URLSearchParams();

    for (const key in input) {
        // @ts-ignore
        if (Array.isArray(input[key])) {
            // @ts-ignore
            input[key].forEach(val => {
                params.append(key + '[]', val)
            })
        } else {
            // @ts-ignore
            params.append(key, input[key]);
        }
    }

    return '?' + params.toString();
}

// @ts-ignore
// TODO: This whole method needs to be rewritten
export function getFinalizedColumns(allColumns, userSettings) {
    copyCellValueToInternalProperty(allColumns);

    if (!userSettings || userSettings.length === 0) {
        return allColumns;
    }

    // Filter out user-hidden columns
    allColumns = allColumns.filter((column: DataTableColumn) =>
        !userSettings.some((s: UserSetting) => s.key === column.label && s.value == 'hide'));

    return allColumns;
}

// @ts-ignore
//persisting the same order in table as we have in grid file.
export function persistGridOrder(userSelectedColumns, allColumns) {

    // @ts-ignore
    return userSelectedColumns.sort((a, b) => {
        // @ts-ignore
        return allColumns.findIndex(obj => obj.Header === a.Header) - allColumns.findIndex(obj => obj.Header === b.Header);
    })
}

export const getValueOrDefault = (value: any, defaultValue: any) => {
    if (isNullOrEmpty(value)) {
        return defaultValue;
    }

    return value;
}

// Returns a clickable <a> that adds the given path to the history, and renders the given text
export const createClickableLink = (path = "", text = "", userSetting: any, isIcon = false, className = "", permissions: string[] = []) => {
    // Check if the user has the required permissions
    const clickable = hasPermission(permissions,true);

    let target = '_self'
    if (userSetting) {
        const linkOpeningStyleObject = userSetting?.find((item: any) => item.key === 'linkOpeningStyle');
        if (linkOpeningStyleObject && linkOpeningStyleObject.value === 'NEW TAB') {
            target = '_blank';
        }
    }
    return (
        <>
            {
                isIcon ?
                    clickable ? <Link
                        className={className}
                        to={`${path}`}
                        target={target}
                        rel={target === '_blank' ? 'noopener noreferrer' : ''}
                    /> : <i className={className}/>
                    :
                    clickable ? <Link
                        className="text-highlight-6 hover:text-highlight-7 cursor"
                        to={`${path}`}
                        target={target}
                        rel={target === '_blank' ? 'noopener noreferrer' : ''}
                    >
                        {text}
                    </Link> : <span>{text}</span>
            }
        </>
    );
}

export const getHeaderByName = (headerGroups: any, headerName: string) => {
    return headerGroups.length && headerGroups[0].headers ? headerGroups[0].headers.find((c: DataTableColumn) => c.label == headerName) : null;
}

export const shouldHideFromUser = (disallowedCustomerLevels: string[] | undefined, customerLevel: string) => disallowedCustomerLevels?.includes(customerLevel);

export const bindDependents = (column: DataTableColumn, allColumns: DataTableColumn[], refFilterQuery: any, setFilterQuery: Function) => {
    if (!column) return;
    if (!column.filterOptions) return;

    for (let filterOptions of column.filterOptions) {
        if (!filterOptions || !Object.keys(filterOptions).length) {
            return;
        }

        let hasDependents = filterOptions?.dependentColumns && filterOptions?.dependentColumns?.length;
        if (!hasDependents) {
            return;
        }

        for (let dependentColumn of allColumns.filter((el: any) => el.filterOptions)) {
            // @ts-ignore
            for (let dependentFilterOptions of dependentColumn?.filterOptions) {
                if (!dependentFilterOptions?.onDependencyUpdated) continue;
                if (!filterOptions.dependentColumns?.includes(dependentColumn?.label)) continue;
                if (!filterOptions?.filterComponent?.props) continue;

                filterOptions.filterComponent.props.onChange = (event: any) => {
                    const tempQuery: any = {...refFilterQuery.current};
                    if (!event) {
                        // @ts-ignore
                        delete tempQuery[column?.filterOptions[0]?.overrideFilterAccessor || column.accessor];
                    } else {
                        // @ts-ignore
                        tempQuery[column?.filterOptions[0]?.overrideFilterAccessor || column.accessor] = event;
                    }

                    setFilterQuery(tempQuery);

                    allColumns.map((currentColumn: any) => {
                        if (filterOptions.dependentColumns?.includes(currentColumn.label)) {
                            Array.isArray(currentColumn.FilterOptions) && currentColumn.filterOptions.map((cur: FilterOptions) => {
                                if (cur?.onDependencyUpdated) {
                                    cur?.onDependencyUpdated(event);
                                }

                                // cur.filterComponent?.reset();
                                // removing dependent tag keys from filter query on parent tag onChange.
                                delete tempQuery[cur?.overrideFilterAccessor || currentColumn.accessor]
                            });

                            setFilterQuery(tempQuery);
                        }
                    })
                };
            }
        }
    }
}

// Copy our lowercase c property to the forced uppercase C property... the React Table library makes this necessary.
// It's conditional because we want to use the default value of the library's uppercase C when we don't have a lowercase c specified :)
export const copyCellValueToInternalProperty = (columns: DataTableColumn[]) => {
    columns?.forEach((c: any) => {
        if (!c.cell) {
            return;
        }

        c.Cell = c.cell;
    });
};

export function SearchSelectWithIndeterminateBoolean(placeholderText: any, accessor: any, trueLabel = "True", falseLabel = "False", quickFilters = null, isConstantData = true) {
    return SearchSelectWithList([
        {label: trueLabel, value: true},
        {label: falseLabel, value: false}
    ], "label", "value", placeholderText, false, accessor, null, quickFilters, isConstantData);
}