import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {sliceNames} from "../../helpers/constants";

export const globalSliceReducerInitialState = {
    isCollapsed:true,
    error: null,
}

const globalSlice = createSlice({
    name: sliceNames.GLOBAL,
    initialState: globalSliceReducerInitialState,
    reducers: {
        setShowSidebar: (state, action: PayloadAction<boolean>) => {
            state.isCollapsed = action.payload;
        },
        setGlobalError(state, action) {
            state.error = action.payload;
        },
        clearGlobalError(state) {
            state.error = null;
        },
    }
})
export const {setShowSidebar} = globalSlice.actions
export const { setGlobalError, clearGlobalError } = globalSlice.actions;
export default globalSlice.reducer;