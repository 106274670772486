import React, {FC, useEffect, useMemo} from "react";
import {IoMdSettings} from "react-icons/io";
import {FaClock} from "react-icons/fa6";
import DashboardCardView from "../../components/common/DashboardCardView";
import MyWorkTab from "./myWork/MyWorkTab";
import TeamWorkTab from "./teamWork/TeamWorkTab";
import PendingTeamWorkTab from "./pendingTeamWork/PendingTeamWorkTab";
import {Tabs} from "react-simple-tabs-component";
import {useCheckIsUserLeaderOfAnyTeamQuery, useCheckIsUserMemberOfAnyTeamQuery} from "../user/users/usersApi";
import {FaCog} from "react-icons/fa";

const Home: FC = () => {

    const isUserLeader = useCheckIsUserLeaderOfAnyTeamQuery("");
    const isUserMember = useCheckIsUserMemberOfAnyTeamQuery("");

    useEffect(() => {
        document.title = "Home";
    });

    const tabs = useMemo(() => {
        const result: any[] = [];

        if (isUserMember?.data === true) {
            result.push({
                label: 'My Work',
                Component: MyWorkTab
            });
        }

        if (isUserLeader?.data === true) {
            result.push({
                label: 'Team Members Work',
                Component: TeamWorkTab
            });
        }

        if (isUserMember?.data === true) {
            result.push({
                label: 'Pending Team Work',
                Component: PendingTeamWorkTab
            });
        }

        return result;
    }, [isUserLeader?.data, isUserMember?.data]);

    return (
        <>
            <div className="grid lg:grid-cols-4 sm:grid-cols-1 md:grid-col-2 gap-4 mt-8 ms-4 me-5">
                <DashboardCardView text='Active Orders' icon={FaCog} data={15}
                                   className='text-highlight-3'/>
                <DashboardCardView text='Rush Orders' icon={FaClock} data={3}
                                   className='text-highlight-3'/>
            </div>

            {tabs.length > 0 &&
                <div className="px-4 py-2">
                    <Tabs tabs={tabs} className="bootstrap-tabs-component mt-3"/>
                </div>
            }
        </>
    )
}

export default Home;