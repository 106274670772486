import React from 'react'

import {IconType} from 'react-icons';
interface DashboardCardViewProps {
    text: string;
    icon: IconType;
    data: number;
    className: string
}

const DashboardCardView: React.FC<DashboardCardViewProps> = ({text, icon: Icon, data, className}) => {
    return (
        <div className="px-3 py-6  bg-surface-2 rounded cursor-pointer hover:scale-105 transform">
            <div className="flex justify-between items-center">
                <div>
                    <h3 className="text-text-3 text-base hover:text-dark-blue hover:transition-all duration-500 ease-in-out">{text}</h3>
                    <h3 className="text-text-1 font-semibold text-xl">{data}</h3>
                </div>
                <div className="w-14 h-24 items-center flex text-center justify-center">
                    <Icon className={`text-3xl ${className}`}/>
                </div>
            </div>
        </div>
    )
}

export default DashboardCardView