import React, {ChangeEvent, FC} from "react";

interface TextAreaFieldProps {
    label: string;
    value?: string;
    placeholder?: string;
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    className?: string;
    required?: boolean;
    name?: string;
    rows?: number;
}

const TextAreaField: FC<TextAreaFieldProps> = ({
                                                   label,
                                                   value,
                                                   placeholder = "",
                                                   required = false,
                                                   onChange,
                                                   className,
                                                   name,
                                                   rows = 3,
                                               }) => {
    return (
        <div className={`col-span-12 md:grid md:grid-cols-12 gap-3`}>
            <div className="col-span-3 sm:mt-2 lg:mt-0 mt-2 flex items-center">
                <div className="text-text-1">{required ? `*${label}` : label}</div>
            </div>
            <div className="col-span-9">
                <textarea
                    name={name}
                    className={`placeholder:text-text-2 rounded-md border border-surface-5 text-text-1 form_control p-2 w-full mt-3 bg-surface-2 ${className}`}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    rows={rows}
                />
            </div>
        </div>
    );
};

export default TextAreaField;
