import {serialize} from "../../helpers/utils/Utils";
import {strApi} from "../../app/strApi";
import {
    getAuthorizationHeader,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../helpers/utils/RtkQueryUtils";

export const ordersApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        // Define the endpoint for searching orders
        getOrderById: build.query({
            query: (orderId) => {
                return {
                    url: `/orders/${orderId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        getOrderLogList: build.query({
            query: ({orderId, logTypes}) => {
                return {
                    url: `/orders/${orderId}/logs?${serialize({logTypes: logTypes})}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        getOrderNoteList: build.query({
            query: (orderId) => {
                return {
                    url: `/orders/${orderId}/notes`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ["orderNoteList"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        createNote: build.mutation({
            query: ({orderId, requestBody, param}) => {
                let paramData = serialize(param);

                return {
                    url: `/orders/${orderId}/notes?${paramData}`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ["orderNoteList", "searchCannedNotes"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "Order Note")
            },
        }),
        updateNoteVisibility: build.mutation({
            query: ({orderId, noteId}) => {
                return {
                    url: `/orders/${orderId}/notes/${noteId}/visibility`,
                    method: 'PUT',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Order Note")
            },
        }),
        createOrder: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/orders`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "Order")
            },
        }),
        getContactEmailById: build.query({
            query: (orderId) => {
                return {
                    url: `/orders/contact-emails/${orderId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
    }),
});

export const {
    useGetOrderByIdQuery,
    useGetOrderNoteListQuery,
    useGetOrderLogListQuery,
    useUpdateNoteVisibilityMutation,
    useCreateOrderMutation,
    useCreateNoteMutation,
    useGetContactEmailByIdQuery
} = ordersApi;