import React, {ChangeEvent, FC} from "react";
import {CreateOrderTabProps} from "./OrderDetailsTab";
import {filterObjectFromArray, isNullOrEmpty, reorderProperties} from "../../../helpers/utils/Utils";
import {useGetAllCountyByStateAbbrQuery} from "../../data/counties/countiesApi";
import {useCreateOrderMutation} from "../ordersApi";
import {useNavigate} from "react-router-dom";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import InputField from "../../../components/common/form/InputField";
import ButtonTray from "../../../components/common/form/ButtonTray";


const FinishingTab: FC<CreateOrderTabProps> = ({createOrderData, setCreateOrderData, setTab}) => {

    const navigate = useNavigate()
    const countyByStateAbbrApiResult = useGetAllCountyByStateAbbrQuery(createOrderData?.state?.value)
    const [createOrder, createOrderApiResponse] = useCreateOrderMutation();

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setCreateOrderData({
            ...createOrderData,
            [name]: value
        })
    }

    const handleCreateOrder = () => {
        let promises = [];

        for (let i = 0; i < createOrderData?.packageList.length; i++) {
            const curPackage = createOrderData?.packageList[i];
            const file = curPackage['file'];

            // Check if 'file' exists and is a valid File or Blob object
            if (!(file instanceof Blob || file instanceof File)) {
                continue;
            }

            // Promise for the main document
            let mainDocPromise = new Promise(resolve => {
                const reader = new FileReader();
                reader.onload = () => {
                    // @ts-ignore
                    const base64String = reader.result.split(',')[1]; // Extract base64 part

                    // Prepare the main document structure
                    const document = {
                        name: curPackage?.customFileName || curPackage?.fileName?.value,
                        type: curPackage?.documentType?.value,
                        grantees: curPackage?.grantees?.map((el: any) => reorderProperties(el)),
                        grantors: curPackage?.grantors?.map((el: any) => reorderProperties(el)),
                        content: base64String,
                        helpers: [],
                        consideration:curPackage?.consideration
                    };

                    // Process helper documents for this package
                    let helperPromises = curPackage.helperDocuments.map((curHelper: any) => {
                        const helperFile = curHelper['file'];

                        // Check if helper file is a valid File or Blob object
                        if (!(helperFile instanceof Blob || helperFile instanceof File)) {
                            return Promise.resolve(null); // Skip invalid helper files
                        }

                        return new Promise(helperResolve => {
                            const helperReader = new FileReader();
                            helperReader.onload = () => {
                                // @ts-ignore
                                const helperBase64String = helperReader.result.split(',')[1];

                                const helper = {
                                    label: curHelper?.label,
                                    content: helperBase64String
                                };

                                helperResolve(helper);
                            };

                            helperReader.readAsDataURL(helperFile);
                        });
                    });

                    // Wait for all helper promises to resolve
                    Promise.all(helperPromises).then(helpers => {
                        // @ts-ignore
                        document.helpers = helpers.filter(helper => helper !== null);
                        resolve(document);
                    });
                };

                reader.readAsDataURL(file);
            });

            promises.push(mainDocPromise);
        }

        // Wait for all document promises to finish
        Promise.all(promises).then(async documents => {
            const inputOrderObj = {
                orderSource: "WEB",
                orderNumber: createOrderData?.customerOrderNumber,
                customerId: createOrderData?.customerId?.value,
                branchId: createOrderData?.branchId?.value,
                countyId: filterObjectFromArray(countyByStateAbbrApiResult.data, "name", createOrderData.countyName?.label)?.id,
                product: createOrderData?.productType?.value,
                notes: isNullOrEmpty(createOrderData?.note) ? [] : [createOrderData?.note],
                productRequest: {
                    docPrepRequest: null,
                    recordingRequest: {
                        packageRequest: {
                            documents: documents,
                            notes: []
                        }
                    }
                },
                needByDate: createOrderData?.needByDate
            };

            // Uncomment the code below to make the API call
            await handleRTKQuery(
                async () => {
                    return await createOrder(inputOrderObj).unwrap();
                },
                (response : any) => {
                    const { orderId, id } = response;
                    setCreateOrderData({});
                    navigate(`/orders/${orderId}#${id}`);
                }
            );
        });
    };

    return (
        <div>
            <div className="p-5 rounded-md order_finish_tab_Wrapper mt-5">
                <div className="grid grid-cols-3">
                    {[
                        {label: 'Order No.', value: createOrderData.customerOrderNumber},
                        {label: 'State', value: createOrderData.state?.value},
                        {label: 'County', value: createOrderData.countyName?.label},
                    ].map(({label, value}) => (
                        <div key={label}>
                            <h3 className="text-text-1">{label}</h3>
                            <h4 className="text-text-1">{value || 'N/A'}</h4>
                        </div>
                    ))}
                </div>

                <div className=" mt-5 grid grid-cols-3">

                    {[
                        {label: 'Customer', value: createOrderData.customerId?.label?.split('(')[0]},
                        {label: 'Branch', value: createOrderData.branchId?.label},
                        {label: 'Product', value: createOrderData.productType?.label},
                    ].map(({label, value}) => (
                        <div key={label}>
                            <h3 className="text-text-1">{label}</h3>
                            <h4 className="text-text-1">{value || 'N/A'}</h4>
                        </div>
                    ))}
                </div>

                <div className="overflow-x-auto ">
                    <table className="order_table_Wrapper min-w-full mt-5 table-auto">
                        <thead>
                        <tr>
                            <th className={"text-text-2"}>#</th>
                            <th className={"text-text-2"}>Document</th>
                            <th className={"text-text-2"}>Type</th>
                            {/*<th className={"text-text-2"}>Grantors</th>*/}
                            {/*<th className={"text-text-2"}>Grantees</th>*/}
                        </tr>
                        </thead>
                        <tbody>
                        {
                            createOrderData?.packageList?.length > 0 && createOrderData?.packageList?.map((curPackage: any, index: number) => {
                                return <tr className="border-t border-surface-4" key={index}>
                                    <td className={"text-text-1"}>{index + 1}</td>
                                    <td className={"text-text-1"}>{curPackage?.fileName?.value === "Custom" ? curPackage?.customFileName : curPackage?.fileName?.label}</td>
                                    <td className={"text-text-1"}>{curPackage?.documentType?.label}</td>
                                    {/*<td className={"text-text-1"}*/}
                                    {/*    title={curPackage?.grantors.map((grantor: any) => grantor.firstName).join(', ') || ""}>{truncateText(curPackage?.grantors.map((grantor: any) => grantor.firstName).join(', '), 20)}*/}
                                    {/*</td>*/}
                                    {/*<td className={"text-text-1"}*/}
                                    {/*    title={curPackage?.grantees.map((grantee: any) => grantee.firstName).join(', ') || ""}>{truncateText(curPackage?.grantees.map((grantee: any) => grantee.firstName).join(', '), 20)}</td>*/}
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className=" grid-cols-12 mt-4 sm:block md:grid">

                <InputField
                    label="Need By Date"
                    value={createOrderData?.needByDate}
                    placeholder="Enter Need By Date..."
                    type="date"
                    name={"needByDate"}
                    onChange={handleOnChange}
                />

                <InputField
                    label="Note"
                    value={createOrderData?.note}
                    placeholder="Enter Note..."
                    type="text"
                    name={"note"}
                    onChange={handleOnChange}
                />

            </div>

            <ButtonTray
                buttons={[
                    {
                        buttonProps: {
                            btnText: "Previous",
                            onClick: () => setTab(2),
                            type: "close",
                        },
                        buttonType: "button"
                    },
                    {
                        buttonProps: {
                            btnText: "Submit",
                            onClick: handleCreateOrder,
                            type: "button",
                            isLoading: (createOrderApiResponse?.isLoading),
                        },
                        buttonType: "button",
                    }
                ]}
                align={"end"}
                gap={2}
            />

        </div>
    )
}
export default FinishingTab