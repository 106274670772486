import {strApi} from "../../../app/strApi";
import {
    getAuthorizationHeader,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../../helpers/utils/RtkQueryUtils";
import {serialize} from "../../../helpers/utils/Utils";

export const cannedFileNamesApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        getAllCannedFileNames: build.query({
            query: (tenantId) => {
                return {
                    url: `/canned-file-names?tenantId=${tenantId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        searchCannedFileNames: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/canned-file-names/search?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ['searchCannedFileNames'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        createCannedFileName: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/canned-file-names`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['searchCannedFileNames'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "Canned File Name");
            },
        }),
        getCannedFileNameById: build.query({
            query: (id) => {
                return {
                    url: `/canned-file-names/${id}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ['cannedFileNameById'],
            async onQueryStarted(_, {queryFulfilled}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        updateCannedFileName: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/canned-file-names/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['searchCannedFileNames', 'cannedFileNameById'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Canned File Name");
            },
        }),

    }),
});


export const {
    useGetAllCannedFileNamesQuery,
    useSearchCannedFileNamesQuery,
    useCreateCannedFileNameMutation,
    useGetCannedFileNameByIdQuery,
    useUpdateCannedFileNameMutation
} = cannedFileNamesApi;