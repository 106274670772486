import {strApi} from "../../../app/strApi";
import {getFileName, serialize} from "../../../helpers/utils/Utils";
import {
    getAuthorizationHeader,
    getFileDownloadHeaders,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../../helpers/utils/RtkQueryUtils";
import {downloadFile} from "../../../helpers/utils/FileUtils";

export const teamsApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        searchTeams: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/teams/search?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ['searchTeams'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        createTeam: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/teams`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['searchTeams'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "Team")
            },
        }),
        getTeamById: build.query({
            query: (teamId) => {
                return {
                    url: `/teams/${teamId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
            providesTags: ["teamById"]
        }),

        updateTeam: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/teams/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['searchTeams', 'teamById'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Team")
            },
        }),
        teamMembers: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/teams/members?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ['teamMembers'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        addNewMemberIntoTeam: build.mutation({
            query: ({teamId, userId}) => {
                let paramData = serialize({userId});
                return {
                    url: `/teams/members/${teamId}?${paramData}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                };
            },
            invalidatesTags: ['teamMembers'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Added", "Team Member")
            },
        }),

        removeMemberFromTeam: build.mutation({
            query: ({teamId, userId}) => {
                let paramData = serialize({userId});
                return {
                    url: `/teams/members/${teamId}?${paramData}`,
                    method: 'DELETE',
                    headers: getAuthorizationHeader(),
                };
            },
            invalidatesTags: ['teamMembers'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Removed", "Team Member")
            },
        }),

        teamLeaders: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/teams/leaders?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ['teamLeaders'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        addNewLeaderIntoTeam: build.mutation({
            query: ({teamId, userId}) => {
                let paramData = serialize({userId});
                return {
                    url: `/teams/leaders/${teamId}?${paramData}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                };
            },
            invalidatesTags: ['teamLeaders'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Added", "Team Leader")
            },
        }),

        removeLeaderFromTeam: build.mutation({
            query: ({teamId, userId}) => {
                let paramData = serialize({userId});
                return {
                    url: `/teams/leaders/${teamId}?${paramData}`,
                    method: 'DELETE',
                    headers: getAuthorizationHeader(),
                };
            },
            invalidatesTags: ['teamLeaders'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Removed", "Team Leader")
            },
        }),

        teamRules: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/team-rules?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ['teamRules'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        deleteTeamRule: build.mutation({
            query: (id) => {
                return {
                    url: `/team-rules/${id}`,
                    method: 'DELETE',
                    headers: getAuthorizationHeader(),
                };
            },
            invalidatesTags: ['teamRules'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Deleted", "Team Rule")
            },
        }),

        createTeamRule: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/team-rules`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['teamRules'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Added", "Team Rule")
            },
        }),

        getTeamRuleById: build.query({
            query: (teamId) => {
                return {
                    url: `/team-rules/${teamId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
            providesTags: ["teamRuleById"]
        }),

        updateTeamRule: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/team-rules/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['teamRules', 'teamRuleById'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Team Rule")
            },
        }),

        getAllAssignToMembers: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);

                return {
                    url: `/teams/assign-to-members?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        getAllAssignToLeaders: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);

                return {
                    url: `/teams/assign-to-leaders?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        exportTeamData: build.query({
            query: (query) => {
                return {
                    url: `/teams/export-csv`,
                    method: 'GET',
                    params: query,
                    headers: getFileDownloadHeaders(),
                    responseType: 'arraybuffer',
                    responseHandler: async (response: { blob: () => any; }) => {
                        const blob = await response.blob();
                        downloadFile(blob, getFileName("teams"))
                        return {success: true};
                    },
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

    })
});

export const {
    useSearchTeamsQuery,
    useCreateTeamMutation,
    useGetTeamByIdQuery,
    useUpdateTeamMutation,
    useTeamMembersQuery,
    useAddNewMemberIntoTeamMutation,
    useRemoveMemberFromTeamMutation,
    useTeamLeadersQuery,
    useAddNewLeaderIntoTeamMutation,
    useRemoveLeaderFromTeamMutation,
    useTeamRulesQuery,
    useDeleteTeamRuleMutation,
    useCreateTeamRuleMutation,
    useUpdateTeamRuleMutation,
    useGetTeamRuleByIdQuery,
    useGetAllAssignToMembersQuery,
    useGetAllAssignToLeadersQuery,
    useLazyExportTeamDataQuery
} = teamsApi;