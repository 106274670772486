import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useGetSystemPropertyByIdQuery, useUpdateSystemPropertyByIdMutation} from "./systemPropertiesApi";
import {skipToken} from "@reduxjs/toolkit/query";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import Form from "../../../components/common/form/Form";
import InputField from "../../../components/common/form/InputField";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import AuditableInfoButton from "../../../components/common/form/AuditableInfoButton";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {Routes as AppRoutes} from "../../../routes";
import ButtonTray from "../../../components/common/form/ButtonTray";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";

const UpdateSystemProperty = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "System Properties", url: "/system-properties"},
        {content: "Update System Property", url: ""},
    ])

    const navigate = useNavigate();
    let {systemPropertyId} = useParams();
    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getSystemPropertyById, {systemPropertyId}, {
        redirectToErrorPage: true,
        skip: !systemPropertyId
    });
    const systemPropertyByIdApi = useGetSystemPropertyByIdQuery(systemPropertyId);
    const [propertyData, setPropertyData] = useState<{ [key: string]: string }>({});
    const [updateSystemProperty, updateSystemPropertyApiResponse] = useUpdateSystemPropertyByIdMutation();

    useEffect(() => {
        let obj = systemPropertyByIdApi?.data;
        if (obj) {
            setPropertyData({
                ...obj
            })
        }
    }, [systemPropertyByIdApi]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const UpdateSystemProperty = async () => {
            await updateSystemProperty({
                id: systemPropertyId,
                requestBody: propertyData,
            }).unwrap();
        };

        await handleRTKQuery(UpdateSystemProperty, () => {
            setPropertyData({});
            navigate("/system-properties");
        });
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: String | null }) => {
        const {name, value} = "target" in e ? e.target : e;

        // Update the state with the new value
        setPropertyData((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    }

    return (
        <>
            <div className="px-4 py-2">

                <div className="justify-between sm:block md:flex lg:flex">
                    <Breadcrumb crumbs={crumbs}/>
                    <AuditableInfoButton obj={systemPropertyByIdApi?.data}/>
                </div>

                <div className="flex justify-center mt-3">
                    <div className="bg-surface-2 lg:w-4/6 sm:w-full p-5 rounded-md">
                        <h3 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3">Update System
                            Property</h3>
                        <Form onSubmit={handleSubmit} fields={<>
                            <div className="grid-cols-12 mt-6 sm:block md:grid lg:grid">

                                <InputField
                                    name={"name"}
                                    label="Name"
                                    onChange={handleOnChange}
                                    value={propertyData?.name || ""}
                                    type="text"
                                    placeholder="Enter Name..."
                                    required={true}
                                />

                                <InputField
                                    name={"value"}
                                    label="Value"
                                    onChange={handleOnChange}
                                    value={propertyData?.value || ""}
                                    type="text"
                                    placeholder="Enter Value..."
                                    required={true}
                                />


                            </div>

                            <ButtonTray
                                buttons={[
                                    {
                                        buttonProps: {
                                            btnText: "Cancel",
                                            onClick: () => navigate(AppRoutes.SystemProperties.path),
                                            type: "close",
                                        },
                                        buttonType: "button"
                                    },
                                    {
                                        buttonProps: {
                                            btnText: "Submit",
                                            type: "submit",
                                            isLoading: (updateSystemPropertyApiResponse.isLoading),
                                            isVisible: hasPermission(["SPRO-U"])
                                        },
                                        buttonType: "button",

                                    }
                                ]}
                                align={"end"}
                                gap={2}
                            />

                        </>}/>
                    </div>
                </div>

            </div>
        </>
    )
}
export default UpdateSystemProperty;