import Breadcrumb, {Crumb} from "../../../components/common/Breadcrumbs";
import React, {ChangeEvent, FC, FormEvent, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Form from "../../../components/common/form/Form";
import InputField from "../../../components/common/form/InputField";
import {OptionType} from "../../../helpers/utils/StyleUtils";
import {useCreateRoleMutation, useUpdateRoleMutation, useGetAllPermissionsQuery, useGetRoleByIdQuery} from "./rolesApi";
import {allPermissionCheckedOnColumns, allPermissionChecked, filterPermission} from "../../../helpers/utils/RoleUtils";
import {skipToken} from "@reduxjs/toolkit/query";
import {useEffect} from "react";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {Routes as AppRoutes} from "../../../routes";
import AuditableInfoButton from "../../../components/common/form/AuditableInfoButton";
import ButtonTray from "../../../components/common/form/ButtonTray";

interface props {
    crumbs: Crumb[];
    title: string,
    btnText: string,
    isEditable?: boolean
}

const RoleForm: FC<props> = ({crumbs, title, btnText, isEditable = true}) => {

    const navigate = useNavigate();
    let {roleId} = useParams();
    let [permissions, setPermissions] = useState<any>([]);
    let [permissionLabel, setPermissionsLabel] = useState<any>([]);
    const [roleData, setRoleData] = useState<any>({});

    const [createRole, createRoleApiResponse] = useCreateRoleMutation();
    const [updateRole, updateRoleApiResponse] = useUpdateRoleMutation();
    const permissionsQuery = useGetAllPermissionsQuery("");
    const roleByIdQuery = useGetRoleByIdQuery(roleId ?? skipToken);

    useEffect(() => {
        if (permissionsQuery?.data) {
            // Extract unique permission labels
            const labels = [...new Set(permissionsQuery.data.map((item: any) => item.name.split(' ')[0]))];
            setPermissionsLabel(labels);

            if (roleId && roleByIdQuery?.data) {
                // Create permissions with isChecked flag based on roleByIdQuery
                const updatedPermissions = permissionsQuery.data.map((item: any) => ({
                    ...item,
                    isChecked: roleByIdQuery.data.permissions.includes(item.name),
                }));

                setPermissions(updatedPermissions);
            } else {
                // If no roleId, set permissions without isChecked flag
                setPermissions(permissionsQuery.data);
            }
        }
    }, [permissionsQuery?.data, roleByIdQuery?.data, roleId]);

    useEffect(() => {
        if (roleByIdQuery?.data) {
            const {name, authorityLevel} = roleByIdQuery.data;
            setRoleData({name, authorityLevel});
        }
    }, [roleByIdQuery?.data]);


    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: OptionType | null }) => {
        const {name, value} = "target" in e ? e.target : e;
        setRoleData({
            ...roleData,
            [name]: value
        });
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Create the common object
        const commonObj = {
            ...roleData,
            permissions: permissions
                .filter((permission: any) => permission.isChecked)
                .map((permission: any) => permission.name),
        };

        const createOrUpdateRole = async () => {
            if (btnText === "Create") {
                await createRole(commonObj).unwrap();
            } else {
                await updateRole({
                    id: roleId,
                    requestBody: commonObj,
                }).unwrap();
            }
        };

        await handleRTKQuery(createOrUpdateRole, () => {
            setRoleData({});
            navigate(AppRoutes.Roles.path);
        });
    }

    const handleCheckAll = (e: any) => {
        const {name, checked} = e.target;
        let tempPer = permissions.map((el: any) => el.name.split(' ')[1] === name ? {
            ...el,
            isChecked: checked
        } : el);
        setPermissions(tempPer);
    }

    const handleChange = (e: any) => {
        const {name, checked} = e.target;
        if (name.includes("all")) {
            let id = e.target.id;
            let tempPer = permissions.map((el: any) => el.name.split(' ')[0] === id ? {
                ...el,
                isChecked: checked
            } : el);
            setPermissions(tempPer);
        } else {
            let tempPer = permissions.map((el: any) => el.name === name ? {
                ...el,
                isChecked: checked
            } : el);
            setPermissions(tempPer);
        }
    };

    return (
        <>
            <div className="px-4 py-2">
                <div className={"justify-between sm:block md:flex lg:flex"}>
                    <Breadcrumb crumbs={crumbs}/>
                    {roleId && <AuditableInfoButton obj={roleByIdQuery?.data}/>}
                </div>
                <div className="flex justify-center mt-3">
                    <div className="bg-surface-2 lg:w-4/6 sm:w-full p-5 rounded-md">
                        <h3 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3">{title}</h3>
                        <Form onSubmit={handleSubmit} fields={
                            <>
                                <div className="grid grid-cols-12">

                                    <InputField
                                        name={"name"}
                                        label="Name"
                                        onChange={handleOnChange}
                                        value={roleData?.name || ""}
                                        type="text"
                                        placeholder="Enter Name..."
                                        required={true}
                                    />

                                    <InputField
                                        name={"authorityLevel"}
                                        label="Authority Level"
                                        onChange={handleOnChange}
                                        value={roleData?.authorityLevel || ""}
                                        type="number"
                                        placeholder="Enter Authority Level..."
                                        required={true}
                                    />

                                    <div className="col-span-12 md:grid md:grid-cols-12 gap-3 ">
                                        <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2">
                                            <div className="text-text-1">*Permissions</div>
                                        </div>
                                        <div className="col-span-9 relative mt-2">
                                            <div className='row'>
                                                <div className="col-12">
                                                    <div className="border border-surface-5 rounded-md">
                                                        <div className="grid  grid-cols-12">
                                                            <div className='col-span-4'></div>
                                                            <div className='col-span-8'>
                                                                <ul className="m-0 flex justify-between p-3 text-right text-text-1">
                                                                    {["All", "View", "Read", "Create", "Update", "Delete", "Bulk"].map((permission) => (
                                                                        <li key={permission}>{permission}</li>
                                                                    ))}
                                                                </ul>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="with-max-r h-96 overflow-x-scroll custom_scrollbar">
                                                        <div className="border border-surface-5 rounded-md">
                                                            <div className="grid grid-cols-12">
                                                                <div className="col-span-4 mt-2"><span
                                                                    className="ml-2 mt-2"></span></div>
                                                                <div className="col-span-8">
                                                                    <ul className="checks flex justify-between text-right p-3 m-0">
                                                                        <li className={"ml-2 opacity-0"}>1
                                                                        </li>
                                                                        {["View", "Read", "Create", "Update", "Delete", "Bulk"].map((permission) => (
                                                                            <li>
                                                                                <InputField
                                                                                    name={permission}
                                                                                    onChange={handleCheckAll}
                                                                                    type="checkbox"
                                                                                    checked={!allPermissionCheckedOnColumns(permissions, permission)}
                                                                                    labelPosition={"top"}
                                                                                />
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {permissionLabel.map((curPermission: any) => {
                                                            return (<>
                                                                <div className="border border-surface-5 rounded-md">
                                                                    <div className='grid grid-cols-12'>
                                                                        <div className='col-span-4 mt-2'>
                                                                <span
                                                                    className="ml-2 mt-2 text-text-1">{curPermission}</span>
                                                                        </div>
                                                                        <div className='col-span-8'>
                                                                            <ul className="m-0 checks flex justify-between p-3 text-right">
                                                                                <li>
                                                                                    <input type="checkbox"
                                                                                           id={curPermission}
                                                                                           name="allSelect"
                                                                                           checked={!allPermissionChecked(permissions, curPermission)}
                                                                                           onChange={handleChange}
                                                                                    />
                                                                                </li>
                                                                                {["View", "Read", "Create", "Update", "Delete", "Bulk"].map((cur) => (
                                                                                    <li>
                                                                                        <InputField
                                                                                            type="checkbox"
                                                                                            onChange={handleChange}
                                                                                            name={curPermission + ` ${cur}`}
                                                                                            value={curPermission + ` ${cur}`}
                                                                                            checked={filterPermission(permissions, curPermission, ` ${cur}`)?.isChecked || false}
                                                                                            disabled={filterPermission(permissions, curPermission, ` ${cur}`) === undefined}
                                                                                            labelPosition={"top"}
                                                                                        />
                                                                                    </li>
                                                                                ))}

                                                                            </ul>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </>)
                                                        })}
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <ButtonTray
                                    buttons={[
                                        {
                                            buttonProps: {
                                                btnText: "Cancel",
                                                onClick: () => navigate(AppRoutes.Roles.path),
                                                type: "close",
                                            },
                                            buttonType: "button"
                                        },
                                        {
                                            buttonProps: {
                                                btnText: "Submit",
                                                type: "submit",
                                                isLoading: (createRoleApiResponse.isLoading || updateRoleApiResponse.isLoading),
                                                isVisible: isEditable
                                            },
                                            buttonType: "button",

                                        }
                                    ]}
                                    align={"end"}
                                    gap={2}
                                />

                            </>
                        }/>

                    </div>
                </div>
            </div>
        </>
    )
}
export default RoleForm;