import React, {ChangeEvent, FC} from "react";
import {CreateOrderTabProps} from "../OrderDetailsTab";
import ButtonTray from "../../../../components/common/form/ButtonTray";
import {createOptionListForSelectTag, isNullOrEmpty} from "../../../../helpers/utils/Utils";
import SelectField from "../../../../components/common/form/SelectField";
import InputField from "../../../../components/common/form/InputField";
import {useGetAllStatesQuery} from "../../../data/states/statesApi";
import {useGetAllCountyByStateAbbrQuery} from "../../../data/counties/countiesApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {getFullPersonName} from "../../../../helpers/utils/OrderDetailUtils";
import {IoIosArrowForward} from "react-icons/io";
import ModalButton from "../../../../components/common/form/ModalButton";
import {FaTrash} from "react-icons/fa6";
import ConfirmationModal from "../../../../components/layout/modal/ConfirmationModal";
import {PersonInfoForm} from "../recording/PersonInfoForm";

const FincenDetailTab: FC<CreateOrderTabProps> = ({createOrderData, setCreateOrderData, setTab}) => {

    const allStatesApiResult = useGetAllStatesQuery("");
    const {
        data: countyByStateAbbr,
        isLoading: countyByStateAbbrLoading,
        isFetching: countyByStateAbbrFetching
    } = useGetAllCountyByStateAbbrQuery(createOrderData?.state?.value ?? skipToken);

    const isNextButtonEnabled = () => {
        return true;
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: any }) => {
        const {name, value} = "target" in e ? e.target : e;
        setCreateOrderData({
            ...createOrderData,
            [name]: value,
            // If state is de-selected it should empty the countyName field too
            ...(name === "state" ? {"countyName": null} : {})
        })
    }

    return (
        <>
            <InputField required={true} label='Street Address or Legal'/>
            <SelectField
                label="State"
                value={createOrderData?.state}
                placeholder="Select State..."
                options={createOptionListForSelectTag(allStatesApiResult?.data, "stateName", "stateAbbreviation")}
                onChange={(selectedOption) => {
                    handleOnChange({name: "state", value: selectedOption})
                }}
                required={true}
            />

            <SelectField
                label="County"
                value={createOrderData?.countyName}
                placeholder="Select County..."
                options={createOptionListForSelectTag(countyByStateAbbr, "name", "name")}
                onChange={(selectedOption) => {
                    handleOnChange({name: "countyName", value: selectedOption})
                }}
                required={true}
                isLoading={isNullOrEmpty(createOrderData?.state) || countyByStateAbbrLoading || countyByStateAbbrFetching}
            />

            <InputField required={true}
                        label='Expected Sale Price'
                        type='number'/>

            <div className="col-span-12 mt-4 p-4 rounded-sm border border-surface-6 relative">
                <label className="absolute bg-surface-2 -top-3.5 pr-1 text-text-1">Buyers</label>
                <InputField required={true}
                            label='Legal Name'/>

                <div className="col-span-12 md:grid md:grid-cols-12 gap-3 mt-3">
                    <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                        <h2 className="text-text-1">Members</h2>
                    </div>
                    <div className="col-span-9">
                        <div className="border border-surface-6 p-3 rounded-md bg-surface-2">
                            <InputField required={true}
                                        label='Legal Name'/>
                            <SelectField required={true}
                                         label='Contact Type'
                                         placeholder='Select Contact Type...'
                                         options={[]}
                                         onChange={() => {
                                         }}/>
                        </div>
                    </div>
                </div>

                <div className={`col-span-12 md:grid md:grid-cols-12 gap-3`}>
                    <div className="col-span-3"/>
                    <div className="bg-surface-2 pt-2 col-span-9 rounded-t-md">
                        <div className="bg-highlight-2 justify-between flex px-2 py-1 cursor-pointer">
                            <div className="flex gap-1 items-center">
                                <h3 className="text-text-1 font-semibold">John Smith</h3>
                            </div>
                            <div className="flex gap-3">
                                <IoIosArrowForward
                                    className={"text-text-1 text-lg cursor-pointer"}
                                    onClick={() => {
                                    }}
                                />

                                <ModalButton
                                    triggerButton={<FaTrash
                                        className={"cursor-pointer text-text-1 text-lg hover:text-text-hover-1"}
                                        title={"Delete Person Info"}/>}
                                    onOpen={() => {
                                    }}
                                    modal={
                                        <ConfirmationModal
                                            show={true}
                                            setShow={() => {
                                            }}
                                            title={"Delete Person Info"}
                                            bodyMessage={"Are you sure you want to delete this person info?"}
                                            onSubmit={() => {
                                            }}
                                            isLoading={false}
                                        />
                                    }
                                />
                            </div>
                        </div>
                            <div className="col-span-9">

                            </div>
                    </div>
                </div>
            </div>

            <div className="col-span-12 mt-4 p-4 rounded-sm border border-surface-6 relative">
                <label className="absolute bg-surface-2 -top-3.5 pr-1 text-text-1">Sellers</label>
                <InputField required={true}
                            label='Legal Name'/>

                <div className="col-span-12 md:grid md:grid-cols-12 gap-3 mt-3">
                    <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                        <h2 className="text-text-1">Members</h2>
                    </div>
                    <div className="col-span-9">
                        <div className="border border-surface-6 p-3 rounded-md bg-surface-2">
                            <InputField required={true}
                                        label='Legal Name'/>
                            <SelectField required={true}
                                         label='Contact Type'
                                         placeholder='Select Contact Type...'
                                         options={[]}
                                         onChange={() => {
                                         }}/>
                        </div>
                    </div>
                </div>

                <div className={`col-span-12 md:grid md:grid-cols-12 gap-3`}>
                    <div className="col-span-3"/>
                    <div className="bg-surface-2 pt-2 col-span-9 rounded-t-md">
                        <div className="bg-highlight-2 justify-between flex px-2 py-1 cursor-pointer">
                            <div className="flex gap-1 items-center">
                                <h3 className="text-text-1 font-semibold">William Johery</h3>
                            </div>
                            <div className="flex gap-3">
                                <IoIosArrowForward
                                    className={"text-text-1 text-lg cursor-pointer"}
                                    onClick={() => {
                                    }}
                                />

                                <ModalButton
                                    triggerButton={<FaTrash
                                        className={"cursor-pointer text-text-1 text-lg hover:text-text-hover-1"}
                                        title={"Delete Person Info"}/>}
                                    onOpen={() => {
                                    }}
                                    modal={
                                        <ConfirmationModal
                                            show={true}
                                            setShow={() => {
                                            }}
                                            title={"Delete Person Info"}
                                            bodyMessage={"Are you sure you want to delete this person info?"}
                                            onSubmit={() => {
                                            }}
                                            isLoading={false}
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-span-9">

                        </div>
                    </div>
                </div>
            </div>

            <ButtonTray
                buttons={[
                    {
                        buttonProps: {
                            btnText: "Previous",
                            onClick: () => setTab(1),
                            type: "close",
                        },
                        buttonType: "button"
                    },
                    {
                        buttonProps: {
                            btnText: "Next",
                            type: (isNextButtonEnabled()) ? "submit" : "button",
                            onClick: () => setTab(3),
                            isDisabled: (!isNextButtonEnabled()),
                            title: (!isNextButtonEnabled()) ? "Unsaved Changes" : ""
                        },
                        buttonType: "button",

                    }
                ]}
                align={"end"}
                gap={2}
            />
        </>
    )
}

export default FincenDetailTab;
