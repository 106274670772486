export const Routes = {
    // pages
    Default: {
        path: "/"
    },
    Login: {
        path: "/login"
    },
    ForgotPassword: {
        path: "/forgot-password"
    },
    ResetPassword: {
        path: "/reset-password"
    },
    CreatePassword: {
        path: "/create-password"
    },
    Home: {
        path: "/home"
    },
    Order: {
        path: "/orders"
    },

    OrderDetailPage: {
        path: "/orders/:orderId"
    },

    Users: {
        path: "/access-control/users"
    },

    UpdateUser: {
        path: "/access-control/users/:userId"
    },
    CreateUser: {
        path: "/access-control/users/create"
    },

    Roles: {
        path: "/access-control/roles"
    },

    CreateRole: {
        path: "/access-control/roles/create"
    },

    UpdateRole: {
        path: "/access-control/roles/:roleId"
    },

    Teams: {
        path: "/access-control/teams"
    },

    CreateTeam: {
        path: "/access-control/teams/create"
    },

    TeamDetailPage: {
        path: "/access-control/teams/:teamId"
    },

    Customers: {
        path: "/access-control/customers"
    },
    CreateCustomer: {
        path: "/access-control/customers/create"
    },
    CustomerDetailPage: {
        path: "/access-control/customers/:customerId"
    },
    BranchDetailPage: {
        path: "/access-control/customers/:customerId/:branchId"
    },

    SystemProperties: {
        path: "/system-properties"
    },

    UpdateSystemProperties: {
        path: "/system-properties/:systemPropertyId"
    },

    CannedNotes: {
        path: "/canned-notes"
    },

    UpdateCannedNote: {
        path: "/canned-notes/:noteId"
    },

    Counties: {
        path: "/counties"
    },

    CreateCounty: {
        path: "/counties/create"
    },

    UpdateCounty: {
        path: "/counties/:countyId"
    },

    MetroAreas: {
        path: "/metro-areas"
    },
    CreateMetroArea: {
        path: "/metro-areas/create"
    },

    UpdateMetroArea: {
        path: "/metro-areas/:metroAreaId"
    },

    CannedFileNames: {
        path: "/canned-file-names"
    },

    UpdateCannedFileNames: {
        path: "/canned-file-names/:cannedFileNameId"
    },

    CreateCannedFileNames: {
        path: "/canned-file-names/create"
    },

    CannedInvoiceLines: {
        path: "/canned-invoice-lines"
    },

    UpdateCannedInvoiceLine: {
        path: "/canned-invoice-lines/:cannedInvoiceLineId"
    },

    CreateCannedInvoiceLine: {
        path: "/canned-invoice-lines/create"
    },

    PlatformPricing: {
        path: "/accounting/platform-pricing"
    },

    CreatePlatformPricing: {
        path: "/accounting/platform-pricing/create"
    },

    UpdatePlatformPricing: {
        path: "/accounting/platform-pricing/:platFormId"
    },

    TenantPricing: {
        path: "/accounting/tenant-pricing"
    },

    CreateTenantPricing: {
        path: "/accounting/tenant-pricing/create"
    },

    UpdateTenantPricing: {
        path: "/accounting/tenant-pricing/:tenantPricingId"
    },

    PartnerPricing: {
        path: "/accounting/partner-pricing"
    },

    RevenueEvents: {
        path: "/accounting/revenue-events"
    },

    CreatePartnerPricing: {
        path: "/accounting/partner-pricing/create"
    },

    UpdatePartnerPricing: {
        path: "/accounting/partner-pricing/:partnerPricingId"
    },

    VendorPricing: {
        path: "/accounting/vendor-pricing"
    },

    CreateVendorPricing: {
        path: "/accounting/vendor-pricing/create"
    },

    UpdateVendorPricing: {
        path: "/accounting/vendor-pricing/:vendorPricingId"
    },

    Profile: {
        path: "/profile"
    },

    CreateOrder: {
        path: "/orders/create"
    },

    FollowUpTypes: {
        path: "/follow-up-types"
    },

    CreateFollowUpType: {
        path: "/follow-up-types/create"
    },

    UpdateFollowUpType: {
        path: "/follow-up-types/:typeId"
    },

    States: {
        path: "/states"
    },

    CreateState: {
        path: "/states/create"
    },

    UpdateState: {
        path: "/states/:stateId"
    },

    Reporting: {
        path: "/reporting"
    },

    SuccessPage: {
        path: "/success"
    },

    BusinessesTransactionPage : {
        path : "/transaction/businesses"
    },

    IndividualsTransactionPage : {
        path : "/transaction/individuals"
    }
}