import React, {FC, FormEvent} from "react"
import Modal from "../../components/common/form/Modal";
import Form from "../../components/common/form/Form";
import ButtonTray from "../../components/common/form/ButtonTray";

interface resetPasswordProps {
    show: boolean,
    setShow: (show: boolean) => void;
}

const ResetPassword: FC<resetPasswordProps> = ({show, setShow}) => {

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
    }

    return (
        <>
            <Modal title={"Reset Password"} show={show} setShow={setShow}
                   fields={
                       // @ts-ignore
                       <Form onSubmit={handleSubmit} fields={
                           <>
                               <div className="mb-5 text-base font-normal text-text-1 border-b border-surface-3">
                                   <input
                                       className="w-full bg-surface-2 rounded-md border border-gray text-text-1 form_control px-2 py-2 mb-3"
                                       type="password"
                                       placeholder="Enter Old Password..."
                                   />
                                   <input
                                       className="w-full bg-surface-2 rounded-md border border-gray text-text-1 form_control px-2 py-2 mb-3"
                                       type="password"
                                       placeholder="Enter New Password..."
                                   />
                                   <input
                                       className="w-full bg-surface-2 rounded-md border border-gray text-text-1 form_control px-2 py-2"
                                       type="password"
                                       placeholder="Enter Confirm Password..."
                                   />
                               </div>


                               <ButtonTray
                                   buttons={[
                                       {
                                           buttonProps: {
                                               btnText: "Cancel",
                                               onClick: () => setShow(false),
                                               type: "close",
                                           },
                                           buttonType: "button"
                                       },
                                       {
                                           buttonProps: {
                                               btnText: "Submit",
                                               type: "submit",
                                           },
                                           buttonType: "button",

                                       }
                                   ]}
                                   align={"end"}
                                   gap={2}
                               />

                           </>
                       }>
                       </Form>
                   }/>

        </>
    )

}
export default ResetPassword