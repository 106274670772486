import {
    BaseQueryFn,
    createApi,
    fetchBaseQuery,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
    retry
} from '@reduxjs/toolkit/query/react'
import {setGlobalError} from "./globalSlice/globalSlice";
import {QueryReturnValue} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {skipToken} from "@reduxjs/toolkit/query";

let errorHandlingOptions: ExtraOptions | null = null;

// Define the extra options interface
export interface ExtraOptions {
    redirectToErrorPage?: boolean;
    skip?: boolean;
}

export const fetchWithExtraOptions = <T>(
    endpoint: keyof typeof strApi.endpoints,
    args: T,
    extraOptions: ExtraOptions = {}
) => {
    const skip = errorHandlingOptions?.skip || false;

    if (skip) {
        return skipToken;
    }

    errorHandlingOptions = extraOptions;

    // @ts-ignore
    return endpoint.initiate();
};


const customBaseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
});

// Define the custom base query with error handling
const baseQueryWithErrorHandling: BaseQueryFn<
    string | { url: string; method?: string; headers?: Record<string, string> },
    unknown,
    FetchBaseQueryError,
    ExtraOptions,
    FetchBaseQueryMeta
> = async (args, api, extraOptions) => {

    const redirectToErrorPage = errorHandlingOptions?.redirectToErrorPage || false
    const result: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta> = await customBaseQuery(args, api, extraOptions);
    if (result.error && redirectToErrorPage) {
        api.dispatch(
            setGlobalError({
                statusCode: result.error.status || 500,
                message: (result.error.data as { message?: string })?.message || "An unexpected error occurred",
            })
        );
    }

    return result;
};

// Wrap with retry logic and set maxRetries to 1
export const strApi = createApi({
    reducerPath: "strApi",
    baseQuery: retry(baseQueryWithErrorHandling, {
        maxRetries: 3,
    }),
    refetchOnMountOrArgChange: 15,

    tagTypes: [
        'userSettings',
        'userProfileInfo',
        'orderNoteList',
        'productDocumentList',
        'productById',
        'requirementsForInstrument',
        'recipientInstruments',
        'systemPropertyById',
        'searchUsers',
        'userById',
        'searchSystemProperties',
        'recordingPackageByProductId',
        'searchCannedNotes',
        'searchCustomers',
        'customerById',
        'searchBranches',
        'searchRegions',
        'regionById',
        'branchContacts',
        'pdfSettingsByCustomerId',
        'fetchSimplifileTokenStatus',
        'followUpTypeById',
        'searchFollowUpTypes',
        'searchProducts',
        'findAllRoles',
        'roleById',
        'searchStates',
        'stateById',
        'cannedNoteById',
        'searchCounties',
        'countyById',
        'searchTeams',
        'teamById',
        'teamLeaders',
        'teamMembers',
        'teamRules',
        'teamRuleById',
        'searchMetroAreas',
        'searchCannedFileNames',
        'cannedFileNameById',
        'metroAreaById',
        'searchPlatformPricing',
        'revenueEvents',
        'platformPricingById',
        'searchTenantPricing',
        'tenantPricingById',
        'searchPartnerPricing',
        'partnerPricingById',
        'searchVendorPricing',
        'vendorPricingById',
        'assignmentLogs',
        'searchInvoiceLines',
        'invoiceLineById',
        'productPricing'
    ],
    endpoints: () => ({}),
})